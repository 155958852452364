import React from "react";
import {
  Preloader,
  Page,
  List,
  ListItem,
  ListInput,
  Icon,
  Link,
  Popover,
  View,
} from "framework7-react";
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import axios from "axios";
import DataGrid from "../../Datagrid/DataGrid";
var dynamicPopover = null;
/**
 * @description Interfata de randare a graficului realizarilor unui salariat intr-un interval selectat
 * @date 08/04/2022
 * @class GraficSalariat
 * @extends {React.Component}
 */
class GraficSalariat extends React.Component {
  constructor(props) {
    super(props);
    this.height = window.innerHeight - 120;
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    var listaSalariati = [...this.Salariati];
    listaSalariati.shift();
    this.state = {
      informatiiPopover: null,
      listaFiltrare: ["2", "3", "6"],
      dataFiltrareStart: this.props.dataFiltrareStart
        ? moment(this.props.dataFiltrareStart).format()
        : moment(),
      dataFiltrareEnd: this.props.dataFiltrareEnd
        ? moment(this.props.dataFiltrareEnd).format()
        : moment(),
      dataSourceTable: [],
      dataSource: [],
      inputs: [
        { key: "Id" },
        {
          key: "NumeSalariat",
          tip: "selectbox",
          lista: this.$f7.data.SalariatiActivi,
          listaEdit: this.Salariati,
        },
        {
          key: "Task",
          keyBaza: "iD_PROIECT",
          tip: "selectbox",
          lista: this.$f7.data.Proiecte,
        },
        {
          key: "refId",
          keyBaza: "iD_PROIECTE",
          tip: "selectbox",
          lista: this.$f7.data.Proiecte,
        },
        {
          key: "Comentariu",
          requiredAdd: false,
          requiredEdit: false,
        },
        {
          key: "ComentariuPlus",
          requiredAdd: false,
          requiredEdit: false,
        },
        {
          key: "MomentInceput",
          tip: "datebox",
          requiredAdd: true,
          requiredEdit: true,
          minDate: moment().format(),
          maxDate: moment().format(),
        },
        {
          key: "MomentSfarsit",
          tip: "datebox",
          requiredAdd: true,
          requiredEdit: true,
          minDate: moment().format(),
          maxDate: moment().format(),
        },
        { key: "TimpLucrat" },
        { key: "TimpLucratMinute", tip: "number" },
        { key: "Client", tip: "selectbox", lista: this.$f7.data.Firme },
        { key: "Cerinta", keyBaza: "cerinta" },
        { key: "ComentariuPV", keyBaza: "comentariuPV" },
      ],
      tipAfisare:
        this.props.tipAfisare !== undefined ? this.props.tipAfisare : "Grafic",
      groupdId: null,
      target: null,
      SalariatCuloare: { id: 3, culoare: "#ffffff" },
      ListaSalariati: listaSalariati,
      SalariatSelectat: this.$f7.data.idSalariatFunctii,
      dataLoaded: false,
      linkApi: "/api/RealizareSarcini",
      data: [
        {
          id: 1,
          group: 1,
          title: "",
          start_time: moment().add(-0.5, "hour"),
          end_time: moment().add(5, "hour"),
        },
        {
          id: 2,
          group: 2,
          title: "",
          start_time: moment(),
          end_time: moment().add(1, "hour"),
        },
        {
          id: 3,
          group: 2,
          title: "",
          start_time: moment().add(2, "hour"),
          end_time: moment().add(3, "hour"),
        },
      ],
      groups: [
        { id: 1, title: "Total" },
        { id: 2, title: "Lucrat" },
      ],
    };
  }

  /**
   * @description Functia de randare a grupului
   * @date 08/04/2022
   * @function
   * @param {*} { group } element ce contine group-ul
   * @param {*} index index-ul elementului curent
   * @memberof GraficSalariat
   */
  groupRenderer = ({ group }, index) => {
    var color = "";
    switch (group.stadiu) {
      case 6: {
        color = "taskinchis";
        break;
      }
      case 1: {
        color = "";
        break;
      }
      case 2: {
        color = "taskactiv";
        break;
      }
      case 3: {
        color = "taskoprit";
        break;
      }
      case 44: {
        color = "taskpropus";
        break;
      }
      default: {
        color = "";
        break;
      }
    }
    return (
      <div
        id={"f" + index}
        onMouseEnter={(e) => {
          var t = e.currentTarget;
          let x = e.clientX;
          let y = e.clientY;
          var value = group.title;
          dynamicPopover = this.$f7.popover.create({
            backdrop: false,
            targetX: x,
            targetY: y - 100,
            targetHeight: 100,
            targetWidth: 200,
            content:
              '<div class="popover">' +
              '<div class="popover-inner" style="padding-left:6px;padding-right:6px;">' +
              "<p>" +
              value +
              "</p>" +
              "</div>" +
              "</div>",
          });
          dynamicPopover.open(t);
        }}
        onMouseLeave={(e) => {
          if (dynamicPopover !== null) {
            dynamicPopover.close();
          }
        }}
        //style={{ color: color }}
        className={color}>
        {group.title}
      </div>
    );
  };

  /**
   * @description Transforma milisecunde intr-un format mai usor de citit " z h m s"
   * @date 08/04/2022
   * @param {*} ms milisecunde
   * @function
   * @memberof GraficSalariat
   */
  dhm = (ms) => {
    var days = Math.floor(ms / (24 * 60 * 60 * 1000));
    var daysms = ms % (24 * 60 * 60 * 1000);
    var hours = Math.floor(daysms / (60 * 60 * 1000));
    var hoursms = ms % (60 * 60 * 1000);
    var minutes = Math.floor(hoursms / (60 * 1000));
    var minutesms = ms % (60 * 1000);
    var sec = Math.floor(minutesms / 1000);
    return days + "z " + hours + "h " + minutes + "m " + sec + " s ";
  };

  /**
   * @description Transforma timpul unei realizari intr-un format mai usor de citit z,h,m,s
   * @function
   * @date 08/04/2022
   * @param {*} item realizarea pentru care se calculeaza
   * @returns obiect cu toate detaliile calculului,z,h,m,s
   * @memberof GraficSalariat
   */
  calculareTimpiLocal = (item) => {
    let curent = 0;

    const inceput = moment(item.intrare).valueOf();
    const sfarsit =
      item.iesire === null ? moment().valueOf() : moment(item.iesire).valueOf();

    if (inceput !== null) {
      curent += sfarsit - inceput;
    }

    // Calculam zile, ore, minute si secunde pentru timpul curent (durata activitatii curente de la un task)
    const zileActual = Math.floor(curent / (8 * 60 * 60 * 1000));
    curent = curent % (8 * 60 * 60 * 1000);
    const oreActual = Math.floor(curent / (60 * 60 * 1000));
    curent = curent % (60 * 60 * 1000);
    const minuteActual = Math.floor(curent / (60 * 1000));
    curent = curent % (60 * 1000);
    const secundeActual = Math.floor(curent / 1000);

    const minuteTotal =
      zileActual * 8 * 60 +
      oreActual * 60 +
      minuteActual +
      +(secundeActual / 60).toFixed(2);

    return {
      zile: zileActual,
      ore: oreActual,
      minute: minuteActual,
      secunde: secundeActual,
      minuteTotal: minuteTotal,
    };
  };

  /**
   * @description Functie de calcul a timpului realizarilor
   * @function
   * @date 08/04/2022
   * @param {*} lista lista realizarilor
   * @memberof GraficSalariat
   */
  calculareTimpi = (lista) => {
    let curent = 0;

    lista.forEach((item, index) => {
      const inceput = moment(item.intrare).valueOf();
      const sfarsit =
        item.iesire === null
          ? moment().valueOf()
          : moment(item.iesire).valueOf();

      if (inceput !== null) {
        curent += sfarsit - inceput;
      }
    });

    // Calculam zile, ore, minute si secunde pentru timpul curent (durata activitatii curente de la un task)
    const zileActual = Math.floor(curent / (8 * 60 * 60 * 1000));
    curent = curent % (8 * 60 * 60 * 1000);
    const oreActual = Math.floor(curent / (60 * 60 * 1000));
    curent = curent % (60 * 60 * 1000);
    const minuteActual = Math.floor(curent / (60 * 1000));
    curent = curent % (60 * 1000);
    const secundeActual = Math.floor(curent / 1000);

    const minuteTotal =
      zileActual * 8 * 60 +
      oreActual * 60 +
      minuteActual +
      +(secundeActual / 60).toFixed(2);

    return {
      zile: zileActual,
      ore: oreActual,
      minute: minuteActual,
      secunde: secundeActual,
      minuteTotal: minuteTotal,
    };
  };

  /**
   * @description Deschide un popup in care apar mai multe informatii despre realizarea/taskul curent selectat
   * @date 08/04/2022
   * @param {*} id id-ul proiectului
   * @param {*} pagina pagina care sa fie deschisa, cea de detalii sau de activitate
   * @memberof GraficSalariat
   */
  openPopup = (id, pagina) => {
    var proiect = this.$f7.data.allProiecte.find((r) => {
      return r.iD_PROIECT === id;
    });
    if (proiect !== undefined) {
      this.$f7.data.Proiect = proiect;
      this.$f7.views.main.router.navigate("/popupDinamic/", {
        props: {
          title: (
            <Link
              tooltip={
                this.state.informatiiPopover.proiect
                  ? this.state.informatiiPopover.proiect.comentariu
                  : ""
              }>
              {this.state.informatiiPopover.denumireTask}
            </Link>
          ),
          disableClose: false,
          component: (
            <View
              removeElements={true}
              componentCache={false}
              onViewInit={(e) => {
                this.$f7.data.routerDreapta = e.router;
                setTimeout(() => {
                  if (pagina !== undefined && pagina === 1) {
                    this.$f7.data.Grid.setState({
                      activeTab:
                        "tab-Grid-Descriere" +
                        this.$f7.data.Grid.state.denumireTab,
                    });
                  }
                  if (pagina !== undefined && pagina === 2) {
                    this.$f7.data.Grid.setState({
                      activeTab:
                        "tab-Grid-TimpLucrat" +
                        this.$f7.data.Grid.state.denumireTab,
                    });
                  }
                }, 350);
              }}
              id="oreLucratePeZiSheet"
              tabDeschis={"tab-Grid-TimpLucrat"}
              name="oreLucratePeZiSheet"
              url={"/agendaGridMainDreaptaActivitate/"}
            />
          ),
        },
      });
    }
  };

  /**
   * @description Ia realizarile salariatului selectat
   * @function
   * @date 08/04/2022
   * @memberof GraficSalariat
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });
    var SalariatCuloare = {
      id: parseInt(this.state.SalariatSelectat),
      //culoare: "hsl(" + Math.random() * 360 + ", 100%, 75%)"
      culoare: "var(--f7-theme-color)",
    };
    var url =
      this.$f7.data.baseURL +
      this.state.linkApi +
      "/GetREALIZARE_SARCINI_BY_SALARIAT/" +
      this.state.SalariatSelectat;
    axios
      .get(url)
      .then((response) => {
        var date = response.data;
        var newGroups = this.props.noSticky ? [] : [
          { id: 1, title: "a" },
          { id: 2, title: "a" },
          { id: 3, title: "a" },
        ];

        console.log("date:", date);
        console.log("newGroups:", newGroups);

        var arr = this.props.noSticky ? [] : [
          {
            id: 1,
            group: 1,
            start_time: moment(),
            end_time: moment().add(1, "hour"),
            title: "",
            canMove: false,
            canResize: false,
          },
          {
            id: 2,
            group: 2,
            start_time: moment(),
            end_time: moment().add(1, "hour"),
            title: "",
            canMove: false,
            canResize: false,
          },
          {
            id: 3,
            group: 3,
            start_time: moment(),
            end_time: moment().add(1, "hour"),
            title: "",
            canMove: false,
            canResize: false,
          },
        ];
        var date2 = [];
        if (this.props.dataFiltrare !== undefined) {
          date2 = date.filter((d) => {
            var startTime = moment(d.intrare);
            var stopTime = moment(d.iesire);
            var isCurrentDate =
              startTime.isSame(moment(this.props.dataFiltrare), "day") &&
              stopTime.isSame(moment(this.props.dataFiltrare), "day");

            return isCurrentDate;
          });
        } else if (
          this.state.dataFiltrareStart !== undefined &&
          this.state.dataFiltrareEnd !== undefined
        ) {
          date2 = date.filter((d) => {
            var exista = false;
            var startTime = moment(d.intrare);
            var stopTime = moment(d.iesire);
            if (
              !moment(this.state.dataFiltrareStart).isSame(
                moment(this.state.dataFiltrareEnd),
                "day"
              )
            ) {
              exista =
                startTime.isBetween(
                  this.state.dataFiltrareStart,
                  this.state.dataFiltrareEnd
                ) &&
                stopTime.isBetween(
                  this.state.dataFiltrareStart,
                  this.state.dataFiltrareEnd
                );
            } else {
              exista =
                startTime.isSame(moment(this.state.dataFiltrareStart), "day") &&
                stopTime.isSame(moment(this.state.dataFiltrareStart), "day");
            }
            if (exista) {
              var pro = this.$f7.data.allProiecte.find((pro) => {
                return pro.iD_PROIECT === d.iD_PROIECT;
              });
              if (pro) {
                if (
                  pro.iD_STADIU !== null &&
                  this.state.listaFiltrare.includes(pro.iD_STADIU.toString())
                ) {
                  exista = true;
                } else {
                  exista = false;
                }
              }
            }

            return exista;
          });
        } else {
          date2 = date;
        }
        var date3 = [];

        date2.map((d) => {
          var pro = this.$f7.data.allProiecte.find((pro) => {
            return pro.iD_PROIECT === d.iD_PROIECT;
          });
          if (pro) {
            var lista = newGroups.find((r) => {
              return r.id === d.iD_PROIECT;
            });
            if (!lista) {
              newGroups.push({
                id: d.iD_PROIECT,
                title: pro.denumire,
                mantis: pro.id_tip_proiect === 55,
                stadiu: pro.iD_STADIU,
              });
            }
          }
          return null;
        });

        date2.map((d) => {
          var colors = [
            { key: null, value: "black" },
            { key: true, value: "green" },
            { key: false, value: "red" },
          ];
          var verificare = this.$f7.data.allProiecte.find((pro) => {
            return pro.iD_PROIECT === d.iD_PROIECT;
          });
          if (verificare) {
            var timpi = this.calculareTimpiLocal(d);
            var durata =
              timpi.zile +
              "z " +
              timpi.ore +
              "h " +
              timpi.minute +
              "m " +
              timpi.secunde +
              " s";
            var objTabel = {
              Id: d.iD_REALIZARE,
              MomentInceput: d.intrare,
              MomentSfarsit: d.iesire,
              NumeSalariat: d.iD_SALARIAT,
              ComentariuPlus: d.comentariU1,
              Comentariu: d.comentariu,
              Client: d.iD_CLIENT,
              Stadiu: this.$f7.data.allProiecte.find((r) => {
                return r.iD_PROIECT === d.iD_PROIECT;
              }).iD_STADIU,
              Operatie: this.$f7.data.allProiecte.find((r) => {
                return r.iD_PROIECT === d.iD_PROIECT;
              }).id_operatiuni,
              TimpLucrat: durata,
              TimpLucratMinute: timpi.minuteTotal,
              Task: d.iD_PROIECT,
              refId: 100000 * d.iD_PROIECT,
              ComentariuPV: d.comentariu_pv,
              Cerinta: this.$f7.data.allProiecte.find((r) => {
                return r.iD_PROIECT === d.iD_PROIECT;
              }).comentariu,
            };
            date3.push(objTabel);
            var colorString = d.manual
              ? colors.find((r) => {
                  return r.key === d.aprobat;
                }).value
              : SalariatCuloare.culoare;
            arr.push({
              id: d.iD_REALIZARE,
              group: d.iD_PROIECT,
              start_time: moment(d.intrare),
              end_time: moment(d.iesire),
              title: "",
              canMove: false,
              canResize: false,
              itemProps: {
                style: {
                  background: colorString,
                  border: "1px solid " + colorString,
                },
                onClick: (e) => {
                  var t = e.currentTarget;
                  var pro = this.$f7.data.allProiecte.find((r) => {
                    return r.iD_PROIECT === d.iD_PROIECT;
                  });
                  var sal = this.$f7.data.Salariati.find((x) => {
                    return x.value === pro.id_dezvoltator;
                  });
                  console.log("pro", pro);

                  var client = this.$f7.data.Firme.find((x) => {
                    return x.value === pro.id_client;
                  });
                  var informatiiPopover = {
                    numeClient: client.key,
                    numeSalariat: sal ? sal.key : "",
                    deadline: pro.deadline,
                    inceput: 0,
                    sfarsit: 0,
                    durata: 0,
                    timpTotalSalariat: 0,
                    timpTotalTask: 0,
                    comentariu: 0,
                    observatie: 0,
                    pornit: 0,
                    realizare: null,
                    denumireTask: pro.denumire,
                    proiect: pro,
                    lucrat: pro.timp_l,
                    estimat: pro.timp_plan_d,
                  };
                  this.setState({ informatiiPopover });
                  var popoverGraficSalariat = this.$f7.popover.get(
                    ".popoverGraficSalariat"
                  );
                  popoverGraficSalariat.open(t);
                },
              },
            });
            return false;
          }
          return null;
        });
        var baza = this.$f7.methods.reconstructieBaza(
          { data: date3 },
          this.state.inputs
        );

        var bazaTable = baza;
        var bazaDistincte = Array.from(
          new Set(bazaTable.map((item) => item.Task))
        );
        var bazaDistincteItems = [];
        bazaDistincte.map((id) => {
          var obj = {
            ...bazaTable.find((f) => {
              return f.Task === id;
            }),
          };
          obj.Id = 100000 * id;

          // var lista = date.filter((r) => {
          //   return r.iD_PROIECT === id;
          // });

          const listaId = bazaTable.filter(item => item.Task === id).map(item => item.Id);
          const lista = date.filter(item => listaId.includes(item.iD_REALIZARE));

          var timpi = this.calculareTimpi(lista);
          var durata =
            timpi.zile +
            "z " +
            timpi.ore +
            "h " +
            timpi.minute +
            "m " +
            timpi.secunde +
            " s";
          obj.refId = -1;
          obj.TimpLucrat = durata;
          obj.TimpLucratMinute = timpi.minuteTotal;
          bazaDistincteItems.push(obj);
          return null;
        });
        baza = baza.concat(bazaDistincteItems);

        this.setState(
          {
            groups: newGroups,
            data: arr,
            dataSource: baza,
            dataSourceTable: bazaDistincteItems,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
                SalariatCuloare: SalariatCuloare,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            data: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Adauga in baza de date o inregistrare noua(Realizare)
   * @function
   * @date 08/04/2022
   * @param {*} data obiectul cu informatiile noii inregistrari
   * @memberof GraficSalariat
   */
  AddValue = (data) => {
    var url =
      this.$f7.data.baseURL + "/api/RealizareSarcini/PostREALIZARE_SARCINI";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  render() {
    return (
      <Page
        className={"PaginaGraficSalariat"}
        pageContent={false}
        style={{ overflow: "auto" }}>
        <>
          <Popover
            onPopoverClose={() => {
              //var target = this.state.targetPopover;
              //target.style.background = this.state.oldColor;
              //this.setState({ targetPopover: target });
            }}
            className="popoverGraficSalariat">
            {this.state.informatiiPopover !== null ? (
              <>
                <Link
                  popoverClose
                  tooltip={
                    this.state.informatiiPopover.proiect
                      ? this.state.informatiiPopover.proiect.comentariu
                      : ""
                  }
                  style={{
                    fontSize: "small",
                    width: "100%",
                    margin: "6px 0px",
                  }}
                  onClick={() => {
                    this.openPopup(
                      this.state.informatiiPopover.proiect.iD_PROIECT,
                      1
                    );
                  }}>
                  {this.state.informatiiPopover.denumireTask}
                </Link>
                <p>
                  <span className={"spanOreLucreate"}>Client:</span>
                  {this.state.informatiiPopover.numeClient}
                </p>
                <p>
                  <span className={"spanOreLucreate"}>Angajat:</span>
                  {this.state.informatiiPopover.numeSalariat}
                </p>
                <p>
                  <span className={"spanOreLucreate"}>Deadline:</span>
                  {moment(this.state.informatiiPopover.deadline).format(
                    "'YY-MM-DD HH:mm"
                  )}
                </p>
                <p>
                  <span className={"spanOreLucreate"}>Lucrat:</span>
                  {this.state.informatiiPopover.lucrat}
                </p>{" "}
                <p>
                  <span className={"spanOreLucreate"}>Estimat:</span>
                  {this.state.informatiiPopover.estimat}
                </p>
                {this.state.informatiiPopover.proiect ? (
                  <Link
                    popoverClose
                    tooltip={"Cere detalii"}
                    style={{
                      fontSize: "small",
                      width: "100%",
                      margin: "6px 0px",
                    }}
                    onClick={() => {
                      var id = this.state.informatiiPopover.proiect.iD_PROIECT;
                      this.$f7.methods.openMesaje(null, id);
                    }}>
                    Cere detalii
                  </Link>
                ) : null}
                {this.state.informatiiPopover.proiect ? (
                  <Link
                    popoverClose
                    tooltip={"Activitate task"}
                    style={{
                      fontSize: "small",
                      width: "100%",
                      margin: "6px 0px",
                    }}
                    onClick={() => {
                      this.openPopup(
                        this.state.informatiiPopover.proiect.iD_PROIECT,
                        2
                      );
                    }}>
                    Activitate task
                  </Link>
                ) : null}
              </>
            ) : null}
          </Popover>

          {this.state.tipAfisare === "Grafic" ? (
            <>
              <Timeline
                style={{ paddingBottom: 25, height: this.height }}
                lineHeight={20}
                sidebarWidth={250}
                groupRenderer={this.groupRenderer}
                onCanvasContextMenu={(groupId, time, e) => {
                  var t = e.currentTarget;
                  t.style.backgroundColor = "red";
                  this.setState({ target: t, groupId: groupId }, () => {
                    this.$f7.actions
                      .create({
                        on: {
                          closed: () => {
                            var targetCurent = this.state.target;
                            targetCurent.style.backgroundColor = "lightgray";
                            this.setState({ target: targetCurent });
                          },
                        },
                        buttons: [
                          {
                            text: "Adauga task",
                            bold: true,
                            onClick: () => {
                              var inputs = [
                                { key: "MomentInceput", tip: "datebox" },
                                { key: "MomentSfarsit", tip: "datebox" },
                                { key: "Comentariu" },
                                { key: "ComentariuPlus" },
                              ];

                              this.$f7.data.router.navigate("/popupAddEdit/", {
                                props: {
                                  allRequired: true,
                                  inputs: inputs,
                                  id: "Id",
                                  buttons: [
                                    {
                                      key: "Salveaza",
                                      onSubmit: (state, den) => {
                                        var proj =
                                          this.$f7.data.allProiecte.find(
                                            (x) => {
                                              return (
                                                x.iD_PROIECT ===
                                                this.state.groupId
                                              );
                                            }
                                          );
                                        var obj = { ...state.editedItem };
                                        var newObj = {
                                          iD_CLIENT: proj.id_client,
                                          intrare: obj.MomentInceput,
                                          iesire: obj.MomentSfarsit,
                                          iD_SALARIAT:
                                            this.$f7.data.idSalariatFunctii,
                                          comentariu: obj.Comentariu,
                                          comentariU1: obj.ComentariuPlus,
                                          iD_PROIECT: proj.iD_PROIECT,
                                          procenT_REALIZARE: 0,
                                          asumat: true,
                                          Unitati: 0,
                                          dupa_lucru: 0,
                                        };
                                        this.AddValue(newObj);
                                        //this.$f7.popup.close(den)
                                        this.$f7.panel.close(den);
                                      },
                                    },
                                  ],
                                },
                              });
                            },
                          },
                        ],
                      })
                      .open();
                  });
                }}
                groups={this.state.groups}
                items={this.state.data}
                defaultTimeStart={moment(this.state.dataFiltrareStart).startOf(
                  "day"
                )}
                defaultTimeEnd={moment(this.state.dataFiltrareEnd).endOf(
                  "day"
                )}>
                <TimelineHeaders
                  className={this.props.noSticky ? "" : "stickyGrafic"}>
                  <SidebarHeader>
                    {({ getRootProps }) => {
                      return <div {...getRootProps()} />;
                    }}
                  </SidebarHeader>
                  <DateHeader unit="primaryHeader" />
                  <DateHeader />
                </TimelineHeaders>
              </Timeline>
            </>
          ) : (
            <div style={{ marginTop: 25 }}>
              <DataGrid
                dataLoaded={this.state.dataLoaded}
                refresh={this.GetValues}
                name={"GraficSalariat"}
                id={"Id"}
                keys={[
                  "NumeSalariat",
                  "Task",
                  "Comentariu",
                  "ComentariuPlus",
                  "TimpLucrat",
                  "Client",
                ]}
                renderAccordion={true}
                refId={"refId"}
                initialRefId={-1}
                renderUpdate={false}
                renderDelete={false}
                renderAdd={false}
                maxHeight={this.height}
                inputs={this.state.inputs}
                dataSource={this.state.dataSource}
              />
            </div>
          )}
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: 58,
              left: 0,
              bottom: 0,
              backgroundColor: "white",
            }}>
            <List
              style={{
                width: "100%",
              }}>
              {this.$f7.data.rol < 2 ? (
                !this.props.noSticky ? (
                  <ListItem
                    style={{ width: 155, display: "inline-block" }}
                    validate
                    title={"Salariat"}
                    label={"Salariat"}
                    className={"roundedOutlinedSmartSelect"}
                    floatingLabel
                    smartSelect
                    smartSelectParams={{
                      virtualList: true,
                      searchbar: true,
                      openIn: "popup",
                      popupTabletFullscreen: true,
                      popupPush: true,
                      closeOnSelect: true,
                      on: {
                        closed: (event) => {
                          var val = event.getValue();
                          this.setState({ SalariatSelectat: val }, () => {
                            this.GetValues();
                          });
                        },
                      },
                    }}>
                    <Icon
                      color={"var(--f7-theme-color)"}
                      size={20}
                      material="list"
                      slot="content-start"
                    />
                    <select name="SelectSalariatiGrafic">
                      {this.state.ListaSalariati.map((obj, index) => (
                        <option
                          key={"listaSalariatiGraficSalariat-" + index}
                          selected={
                            obj.value !== null &&
                            parseInt(obj.value) ===
                              parseInt(this.state.SalariatSelectat)
                          }
                          value={obj.value}>
                          {obj.key}
                        </option>
                      ))}
                    </select>
                  </ListItem>
                ) : null
              ) : null}
              {this.props.tipAfisare !== undefined ? null : (
                <ListItem
                  style={{ width: 155, display: "inline-block" }}
                  className={"roundedOutlinedSmartSelect"}
                  validate
                  title={"Tip grafic"}
                  label={"Tip grafic"}
                  floatingLabel
                  smartSelect
                  smartSelectParams={{
                    virtualList: true,
                    searchbar: true,
                    openIn: "popup",
                    popupTabletFullscreen: true,
                    popupPush: true,
                    closeOnSelect: true,
                    on: {
                      closed: (event) => {
                        var val = event.getValue();
                        this.setState({ tipAfisare: val });
                      },
                    },
                  }}>
                  <Icon
                    color={"var(--f7-theme-color)"}
                    size={20}
                    material="list"
                    slot="content-start"
                  />
                  <select name="SelectSalariatiTabel">
                    <option
                      key={"graficGraficSalariat-0"}
                      selected={"Grafic" === this.state.tipAfisare}
                      value={"Grafic"}>
                      Grafic
                    </option>
                    <option
                      key={"tabelSalariatiGraficSalariat-1"}
                      selected={"Tabel" === this.state.tipAfisare}
                      value={"Tabel"}>
                      Tabel
                    </option>
                  </select>
                </ListItem>
              )}
              <ListInput
                style={{ display: "inline-block", top: -15 }}
                key={"GraficSalariatInterval"}
                label="Interval"
                type="datepicker"
                calendarParams={{
                  rangePicker: true,
                  dateFormat: "dd.mm.yyyy",
                  closeOnSelect: true,
                  on: {
                    closed: (e) => {
                      const val = e.value;
                      var dataFiltrareStart = val[0];
                      var dataFiltrareEnd = val[1];
                      this.setState(
                        {
                          dataFiltrareStart,
                          dataFiltrareEnd,
                        },
                        () => {
                          this.GetValues();
                        }
                      );
                    },
                  },
                }}
                value={[
                  this.state.dataFiltrareStart,
                  this.state.dataFiltrareEnd,
                ]}
                floatingLabel
                placeholder="De la">
                <Icon
                  color={"var(--f7-theme-color)"}
                  size={20}
                  material="today"
                  slot="content-start"
                />
              </ListInput>

              <ListItem
                style={{ display: "inline-block" }}
                title="Stadiu"
                value={this.state.client}
                label="Stadiu"
                validate
                required
                smartSelect
                className="roundedOutlinedSmartSelect"
                smartSelectParams={{
                  virtualList: true,
                  searchbar: true,
                  openIn: "popup",
                  popupTabletFullscreen: true,
                  popupPush: true,
                  closeOnSelect: true,
                  on: {
                    closed: (event) => {
                      var listaFiltrare = event.getValue();
                      this.setState(
                        {
                          listaFiltrare: listaFiltrare,
                        },
                        () => {
                          this.GetValues();
                        }
                      );
                    },
                  },
                }}>
                <Icon
                  color={"var(--f7-theme-color)"}
                  size={20}
                  material="list"
                  slot="content-start"
                />
                <select name="Filtrare" multiple>
                  {[
                    { key: "Pornite", value: 2 },
                    { key: "Oprite", value: 3 },
                    { key: "Inchise", value: 6 },
                  ].map((obj, index) => (
                    <option
                      label={obj.key}
                      selected={this.state.listaFiltrare.includes(
                        obj.value.toString()
                      )}
                      key={"listaFiltrareGraficSalariat-" + index}
                      value={obj.value}>
                      {obj.key}
                    </option>
                  ))}
                </select>
              </ListItem>
            </List>
          </div>
        </>
      </Page>
    );
  }
}
export default GraficSalariat;
